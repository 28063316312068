import React from 'react'
import OnlineExamLoginContainer from '../components/OnlineExamLoginContainer'

const OnlineExamLogin = () => {
  return (
    <div className='online_exam_container'>
        <OnlineExamLoginContainer/>
    </div>
  )
}

export default OnlineExamLogin