import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { examsDateTimeModalFunc, examsStartModalFunc } from '../redux/MainReducer'

const ExamsMainCard = ({ data }) => {
    const dispatch = useDispatch()
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        const examDateTime = new Date(`${data.exam_date}T${data.exam_time}`);
        const currentDate = new Date();
        const differenceInMilliseconds = examDateTime - currentDate;
        const differenceInMinutes = differenceInMilliseconds / (1000 );

        console.log(differenceInMinutes );

        if (differenceInMinutes <= 2*60 ) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [data.exam_date, data.exam_time])

    return (
        <div className="exams_card">
            <span className='exams_card_head'>{data.exam.exam_status ? "Active" : "Deactive"}</span>
            <div className="exams_card_content">
                <p>Exam Type: <span>{data.exam.name}</span></p>
                <p>Variant: <span className='exams_card_content_variant'>{data.exam.variant}</span></p>
                <p>Tarix: <span className='exams_card_content_variant'>{data.exam_date}</span></p>
                <p>Vaxt: <span className='exams_card_content_variant'>{data.exam_time}</span></p>
                <div className="exams_card_buttons">
                    {/* <button style={{backgroundColor: isActive? 'green': 'gray'}} onClick={() => dispatch(examsStartModalFunc(data))} disabled={!isActive}>Start</button> */}
                    {data.is_finished? 
                    <button style={{backgroundColor: "gray"}} disabled>Start</button>
                    :
                    <button onClick={() => dispatch(examsStartModalFunc(data))} >Start</button>
                }
                </div>
            </div>
        </div>
    )
}

export default ExamsMainCard
