import React from 'react'
import HeaderSliderContainer from './HeaderSliderContainer'

const HeaderSlider = () => {
  return (
    <div className='header_slider'>
      
        <HeaderSliderContainer/>
    </div>
  )
}

export default HeaderSlider