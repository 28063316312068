import React, { useEffect } from 'react'
import ExamsResultsMainCard from './ExamsResultsMainCard';
import ExamsResultModal from './modals/ExamsResultModal';
import { useDispatch, useSelector } from 'react-redux';
import { getMyExamResults } from '../actions/MainAction';

const ExamsResultsMain = () => {


    const {examsResultModal,examsUserObj,myExamResults}=useSelector(state=>state.Data)

    const dispatch=useDispatch()


    useEffect(()=>{
        dispatch(getMyExamResults(examsUserObj?.id)) //id
    },[dispatch])


    console.log(examsUserObj);
  

    return (

        <div className="exams_container">

            <div className="exams_cards">
                {
                    myExamResults?.map((examResult,i)=>{
                        return <ExamsResultsMainCard examResult={examResult} key={i}/>
                    })
                }

                {examsResultModal && <ExamsResultModal/>}
            </div>
        </div>

    )
}

export default ExamsResultsMain