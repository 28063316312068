import React from 'react'
import { useDispatch } from 'react-redux'
import { examsDateTimeModalFunc, examsDateTimeUpdateModalFunc } from '../redux/MainReducer'

const DashboardMainCard = ({ data }) => {

    const dispatch = useDispatch()
    return (

        <div className="exams_card">
            {/* <span   className='exams_card_head'>{data.exam_status ? "Active" : "Deactive"}</span> */}
            <div className="exams_card_content">
                <p>Exam Type: <span>{data.name}</span></p>
                <p>Variant: <span className='exams_card_content_variant'>{data.variant}</span></p>
                {/* <p>Tarix və vaxt: <span className='exams_card_content_variant'></span></p> */}
                <div className="exams_card_buttons">
                    {
                        data.is_booked ?
                            <button onClick={() => dispatch(examsDateTimeUpdateModalFunc(data))}>Tarix və vaxtı dəyiş</button> :
                            <button onClick={() => dispatch(examsDateTimeModalFunc(data))}>Tarix və vaxtı təyin edin</button>
                    }


                </div>
            </div>
        </div>

    )
}

export default DashboardMainCard