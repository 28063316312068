import React, { useEffect, useRef } from 'react';
import { FaChevronUp } from "react-icons/fa6";

const TopTo = () => {
    const topToContainer = useRef(null);

    const topToHandle = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        if (window.scrollY < 500) {
            topToContainer.current.style.display = "none";
        } else {
            topToContainer.current.style.display = "flex";
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        // Initial check
        handleScroll();
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div ref={topToContainer} onClick={topToHandle} className='top_to_container' style={{ display: 'none' }}>
            <FaChevronUp />
        </div>
    );
};

export default TopTo;
