import React from 'react'
import { examsResultsModalFunc } from '../redux/MainReducer'
import { useDispatch } from 'react-redux'

const ExamsResultsMainCard = ({examResult}) => {

    const dispatch=useDispatch()

    
    return (
        <div className="exams_card">
            {/* <span className='exams_card_head'>{data.exam.exam_status ? "Active" : "Deactive"}</span> */}
            <div className="exams_card_content">
                <p>Exam: <span>{examResult.student_exam.exam}</span></p>
                {/* <p>Variant: <span className='exams_card_content_variant'>{data.exam.variant}</span></p>
                <p>Tarix: <span className='exams_card_content_variant'>{data.exam_date}</span></p>
                <p>Vaxt: <span className='exams_card_content_variant'>{data.exam_time}</span></p> */}
                <div className="exams_card_buttons">
                    <button onClick={() => dispatch(examsResultsModalFunc(examResult))}>Nəticəyə bax</button>
                </div>
            </div>
        </div>
    )
}

export default ExamsResultsMainCard