import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalCloseFunc, modalOverlayFunc } from '../../redux/MainReducer';

const ExamsResultModal = () => {
    const dispatch = useDispatch();
    const { examsResult } = useSelector(state => state.Data);

    console.log(examsResult);

    return (
        <div className="modal_container">
            <div onClick={() => dispatch(modalOverlayFunc())} className='overlay'></div>
            <div className="branch_create_modal_card">
                <button onClick={() => dispatch(modalCloseFunc())} className='modal_close_btn'>Close</button>
                <div className="branch_update_modal_container">
                    <table>
                        <tbody>
                            <tr>
                                <th style={{ fontWeight: '700' }}>Question</th>
                                <th style={{ fontWeight: '700' }}>DC</th>
                                <th style={{ fontWeight: '700' }}>TC</th>
                                <th style={{ fontWeight: '700' }}>+/-</th>
                            </tr>
                            {examsResult.exam_answers?.map((examAnswer, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{examAnswer.answer_variant}</td>
                                    <td>{examsResult.student_answers[i]?.selected_variant}</td>
                                    <td>
                                        {examsResult.student_answers[i]?.selected_variant === examAnswer.answer_variant ? "+" : "-"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className='exam_results_point'>
                        <p>Ümumi nəticə: <span style={{backgroundColor: "orange", padding: "5px", borderRadius: "6px"}}>{examsResult.result} bal</span></p>
                        <p>Düzgün cavabların sayı: {examsResult.right_answers}</p>
                        <p>Səhv cavabların sayı: {examsResult.wrong_answers}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExamsResultModal;
