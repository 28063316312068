import React from 'react'
import { examsDateFunc, examsTimeFunc, modalCloseFunc, modalOverlayFunc, setRefreshed } from '../../redux/MainReducer'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { baseUrl } from '../../MAIN_API'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const ExamsDateTimeModal = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { examsObj,examsTimeValue,examsDateValue,refreshed } = useSelector(state => state.Data)

    console.log(examsObj);

    console.log(examsDateValue);


    const examsDateTimeHandle=()=>{
        const data={
            exam_time: examsTimeValue,
            exam_date: examsDateValue.split('-')[2] + '-' + examsDateValue.split('-')[1] + '-' + examsDateValue.split('-')[0]
        }

        axios({
            method: "POST",
            url: `${baseUrl}exam/create-exam/${examsObj.id}/`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN_EXAM')}`
            },
            data
        }).then(resp => {
            console.log(resp);
            if (resp.status === 201) {

                Swal.fire({
                    title: "Tarix və vaxt təyin edildi",
                    icon: "success",
                    confirmButtonText: "OK",
                    width: "400px"
                }).then(function () {
                    navigate(`/exams`)
                    if (!refreshed) {
                        dispatch(setRefreshed())
                        window.location.reload();
                    }



                });
            }
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: '',

            }).then(function () {

                navigate(`/exams`)
                if (!refreshed) {
                    dispatch(setRefreshed())
                    window.location.reload();
                }
            })
        })
    }


    return (
        <div className="modal_container">
            <div onClick={() => dispatch(modalOverlayFunc())} className='overlay'></div>
            <div className="branch_create_modal_card">
                <button onClick={() => dispatch(modalCloseFunc())} className='modal_close_btn'>Close</button>
                <div className="branch_update_modal_container">
                    <input value={examsTimeValue} onChange={(e)=>dispatch(examsTimeFunc(e.target.value))} type="time" />
                    <input value={examsDateValue} onChange={(e)=>dispatch(examsDateFunc(e.target.value))} type="date" />

                    <button className='exams_complated_button' onClick={examsDateTimeHandle}>Təyin et</button>


                </div>

            </div>

        </div>
    )
}

export default ExamsDateTimeModal