import React from 'react'
import FooterHeader from './FooterHeader'
import FooterSecond from './FooterSecond'
import FooterEndBg from './FooterEndBg'

const Footer = () => {
    return (
        <footer>
            {/* <FooterHeader /> */}
            {/* <FooterSecond /> */}
            <FooterEndBg />
        </footer>
    )
}

export default Footer