import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExamQuestions, getExams } from '../../actions/MainAction';
import axios from 'axios';
import { baseUrl } from '../../MAIN_API';
import { useNavigate } from 'react-router-dom';
import { setRefreshed } from '../../redux/MainReducer';
import Swal from 'sweetalert2';

const ExamsStartModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { examTypeObj, exams, examDuration, myExams, refreshed } = useSelector(state => state.Data);

    useEffect(() => {
        dispatch(getExams(examTypeObj?.slug));
    }, [dispatch]);

    console.log(examTypeObj?.exam?.exams);


    console.log(myExams);
    // Fənnlər üçün cavab array-ləri obyekti
    const [variantBgArrays, setVariantBgArrays] = useState({});

    const [remainingTime, setRemainingTime] = useState(examDuration * 60);
    const [allAnswerVariants, setAllAnswerVariants] = useState([]);

    const examAnswerVariantHandle = (examIndex, questionIndex, answerIndex, answerData, question) => {
        // Fənn üzrə cavab array-ini alırıq, əgər mövcud deyilsə, yaradırıq
        const examVariantBgArray = variantBgArrays[examIndex] || {};

        // Əgər sual üzrə cavab mövcud deyilsə, yalnız sonuncu seçilmiş cavabı saxlayırıq
        examVariantBgArray[questionIndex] = answerIndex;


        const updatedVariantBgArrays = {
            ...variantBgArrays,
            [examIndex]: {
                ...examVariantBgArray,
                [questionIndex]: answerData.answer_variant
            }
        };
        setVariantBgArrays(prevState => ({
            ...prevState,
            [examIndex]: examVariantBgArray
        }));

        // Extract answer variant and add it to the allAnswerVariants array

        console.log(question);
        // const newAllAnswerVariants = allAnswerVariants.filter(item => !item.includes(question));
        const newAllAnswerVariants = allAnswerVariants.filter(item => item.question !== question.id);

        setAllAnswerVariants(prev => [
            ...newAllAnswerVariants,
            {
                question: question.id,
                selected_variant: answerData.answer_variant,
                given_answer: null
            }
        ]);
    };

    // console.log(allAnswerVariants);

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime === 0) {
                    clearInterval(timer);
                    // Do something when timer reaches 0
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;




    const examFinishedHandle = () => {
        const data = {
            student_answers: allAnswerVariants
        }

        console.log(data);
        axios({
            url: `${baseUrl}exam/student-exam/${examTypeObj?.id}/result/`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN_EXAM')}`
            },
            data
        }).then(resp => {
            console.log(resp);
            if (resp.status === 201) {

                Swal.fire({
                    title: "İmtahan bitdi!",
                    icon: "success",
                    confirmButtonText: "OK",
                    width: "400px"
                }).then(function () {
                    navigate(`/exams`)
                    if (!refreshed) {
                        dispatch(setRefreshed())
                        window.location.reload();
                    }



                });
            }
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: '',

            }).then(function () {

                navigate(`/exams`)
                if (!refreshed) {
                    dispatch(setRefreshed())
                    window.location.reload();
                }
            })
        })
    }

    return (
        <div className="modal_container">
            <div className="branch_create_modal_card exams_modal_card">
                <div className="branch_update_modal_container">
                    <div className="exam_timer">
                        {`${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}
                    </div>
                    {examTypeObj?.exam?.exams?.map((exam, examIndex) => (
                        <div key={examIndex} className='exams_questions_container'>

                            <h2>{exam.exam_name}</h2>
                            {exam.questions.map((question, questionIndex) => (
                                <div key={questionIndex} className='exams_questions_div'>
                                    <div className='exams_questions'>
                                        <span>{question.number}.</span>
                                        <p>{question.question}</p>
                                        {question.question_image && (
                                            <div className='exams_questions_image'>
                                                <img src={question.question_image} alt="" />
                                            </div>
                                        )}
                                    </div>
                                    <div className='exams_answers_container'>
                                        {question.answers.map((answerData, answerIndex) => (
                                            <div key={answerIndex}>
                                                <span
                                                    style={{
                                                        backgroundColor: variantBgArrays[examIndex]?.[questionIndex] === answerIndex ? "orange" : "white",
                                                    }}
                                                    onClick={() => examAnswerVariantHandle(examIndex, questionIndex, answerIndex, answerData, question)}>
                                                    {answerData.answer_variant})
                                                </span>
                                                {answerData.answer_image === null ? (
                                                    <p>{answerData.answer}</p>
                                                ) : (
                                                    <img src={answerData.answer_image} alt="" />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}





                        </div>
                    ))}
                    <button onClick={examFinishedHandle} className='exams_complated_button'>İmtahanı bitir</button>
                </div>
            </div>
        </div>
    );
}

export default ExamsStartModal;


