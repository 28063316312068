import React from 'react'

const OtherSectionHeader = () => {
  return (
    <h2 className='section_header'>
        SON XƏBƏRLƏR
    </h2>
  )
}

export default OtherSectionHeader