import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getExams, getExamsTypes, getMyExams } from '../actions/MainAction'
import ExamsMainCard from './ExamsMainCard'
import ExamsStartModal from './modals/ExamsStartModal'
import ExamsDateTimeModal from './modals/ExamsDateTimeModal'

const ExamsMain = () => {

    const dispatch = useDispatch()
    const { examsTypes,examsModal,examsDateTimeModal,myExams } = useSelector(state => state.Data)

    useEffect(() => {
        dispatch(getMyExams())
    }, [dispatch])


    console.log(myExams);


    console.log(myExams?.filter(data=>data.exam.exam_status===true));
    return (
        <div className="exams_container">

            <div className="exams_cards">
                {
                    myExams?.filter(data=>data.exam.exam_status===true)?.map((data, i) => {
                        return <ExamsMainCard key={i} data={data} />
                    })
                }
            </div>

            {examsModal && <ExamsStartModal/>}
            {/* {examsDateTimeModal && <ExamsDateTimeModal/>} */}


        </div>
    )
}

export default ExamsMain