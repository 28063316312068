import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getExams, getExamsTypes } from '../actions/MainAction'
import DashboardMainCard from './DashboardMainCard'
import ExamsDateTimeModal from './modals/ExamsDateTimeModal'
import ExamsDateTimeUpdateModal from './modals/ExamsDateTimeUpdateModal'

const DashboardMain = () => {

    const dispatch = useDispatch()
    const { examsTypes,examsDateTimeModal,examsDateTimeUpdateModal } = useSelector(state => state.Data)

    useEffect(() => {
        dispatch(getExamsTypes())
    }, [dispatch])


    console.log(examsTypes);

    return (
        <div className="exams_container">

            <div className="exams_cards">
                {/* {
                    exams?.map((data, i) => {
                        return <DashboardMainCard key={i} data={data} />
                    })
                } */}

                {
                    examsTypes?.map((data, i) => {
                        return <DashboardMainCard key={i} data={data} />
                    })
                }

                {examsDateTimeModal && <ExamsDateTimeModal />}
                {examsDateTimeUpdateModal && <ExamsDateTimeUpdateModal />}
            </div>


        </div>
    )
}

export default DashboardMain