import React from 'react'
import ServicesContainer from './ServicesContainer'

const Services = () => {
  return (
    <div className='services'>
        <ServicesContainer/>
    </div>
  )
}

export default Services