import React from 'react'
import NewsContainer from './NewsContainer'

const News = () => {
  return (
    <div className='news'>
        <NewsContainer/>
    </div>
  )
}

export default News